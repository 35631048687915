import { registerApplication, start } from "single-spa";

registerApplication(
  "@pp-admin/core",
  () => System.import("@pp-admin/core"),
  (location) =>
    !location.pathname.includes("menu") && !location.pathname.includes("orders")
);

registerApplication({
  name: "@pp-admin/menu",
  app: () => System.import("@pp-admin/menu"),
  activeWhen: ["/menu"],
});

registerApplication({
  name: "@pp-admin/orders",
  app: () => System.import("@pp-admin/orders"),
  activeWhen: ["/orders"],
});

registerApplication({
  name: "@pp-admin/menu-manager",
  app: () => System.import("@pp-admin/menu-manager"),
  activeWhen: ["/menu-manager"],
});

start();
